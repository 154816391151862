import React, { useState, useEffect } from 'react';
import Modal from './Modal';

const ParticipantLogos = () => {
  // Uses the same breakpoint used throughout the application
  const mobileBreakpoint = 480;
  const [selectedImage, setSelectedImage] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileBreakpoint)

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  })

  const open = (imageDetails) => {
    setSelectedImage(imageDetails)
  }

  const close = () => {
    setSelectedImage(null);
  }

  const handleResize = () => {
    setIsMobile(window.innerWidth <= mobileBreakpoint)
  }

  const companyList = [
    { name: "Department of the Prime Minister and Cabinet", image: '/participant-logos/department-of-the-prime-minister-and-cabinet-logo.png'},
    { name: "Department of Education, Skills and Employment", image: '/participant-logos/department-of-education-skills-and-employment-logo.png'},
    { name: "Fair Work Ombudsman", image: '/participant-logos/fair-work-ombudsman-logo.png'},
    { name: "Gold Coast Health", image: '/participant-logos/gc-health-logo.png'},
    { name: "The University of Newcastle", image: '/participant-logos/uni-newcastle-logo.png'},
    { name: "Griffith University", image: '/participant-logos/griffith-uni-logo.png'},
    { name: "Melton City Council", image: '/participant-logos/city-of-melton-logo.png', link: "https://www.melton.vic.gov.au/Home"},
    { name: "Community Housing Limited", image: '/participant-logos/community-houseing-ltd-logo.png'},
    { name: "Rail Projects Victoria", image: '/participant-logos/rail-projects-victoria-logo.png'},
    { name: "Salvos Legal", image: '/participant-logos/salvos-legal-logo.png',},
    { name: "RMIT University", image: '/participant-logos/rmit-uni-logo.png'},
    { name: "Gilead", image: '/participant-logos/gilead-logo.png'},
    { name: "Jenny's Place", image: '/participant-logos/jennys-place-logo.png'},
    { name: "The Y", image: '/participant-logos/the-y-logo.png'},
    { name: "Yoga Australia", image: '/participant-logos/yoga-australia-logo.png'},
    { name: "Unidex Healthcare", image: '/participant-logos/unidex-healthcare-logo.png'},
    { name: "Screenwest", image: '/participant-logos/screenwest-logo.png'},
    { name: "Screen Australia", image: '/participant-logos/screen-australia-logo.png'},
    { name: "BGC", image: '/participant-logos/bcg-logo.jpg'},
    { name: "Monash IVF", image: '/participant-logos/monash-ivf-logo.jpg'},
    { name: "oOh!Media", image: '/participant-logos/ooh-logo.png'},
    { name: "Ready Resources", image: '/participant-logos/ready-resources-logo.png'},
    { name: "St. John of God", image: '/participant-logos/sjog-healthcare-logo.png'},
    { name: "SWIM Coaches and Teachers Australia", image: '/participant-logos/swim-coaches-logo.png'},
    { name: "Townsville Community Law", image: '/participant-logos/tcl-logo.jpg'}

  ]
  return(
    <div className="participant-logos">
      <h1 className='participant-logos__title'>Join 1000+ organisations building inclusive workplaces</h1>
      <div className='participant-logos__container'>
        {companyList.map((co,i) =>
            <img key={i} className='participant-logos__logo' src={co.image} alt={co.name + ' logo'} onClick={isMobile ? null : () => open(co)}></img>
        )}
      </div>
      {
        selectedImage &&
          <Modal
            isOpen={selectedImage}
            close={close}
            closeBtnStyles={'modal-close-btn modal-close-btn--purple'}
            modalStyles={'br4 mw-100 modal self-center'}
          >
            <img src={selectedImage.image} alt={selectedImage.name + ' logo'}></img>
          </Modal>
      }
    </div>
  )
}

export default ParticipantLogos