import React from 'react'
import TestimonialCard from './TestimonialCard'

const Testimonials = () => {
  return (
    <div className='w-100 flex flex-column flex-row-l flex-wrap'>
      <div className='testimonial-card__column'>
        <TestimonialCard author={'Corrie Playford-Browne'} organizationDetails={'Organisational Development Lead - Diversity & Inclusion, Bupa'}>
          <p>
          At Bupa we have enjoyed the Core Inclusion and Aboriginal and Torres Strait Islander Awareness courses. We have received an outpouring of support from our employees who have really enjoyed the engaging, high quality production of both modules. We have seen our completion rates increase to numbers we’ve never seen before. The modules have been so successful that we’re exploring adding two more modules to our suite of offerings. Leon and the team have been so accommodating and easy to work with. Thank you SBS!          
          </p>
        </TestimonialCard>
        <TestimonialCard author={"A collection of employees' feedback"} organizationDetails={'Gilead Sciences'} logo={{src: '/participant-logos/gilead-logo-carousel.png', alt: 'Gilead Logo'}}>
          <p>
            "A well done educational event. I am a strong ally. There is always something to learn. I appreciate the sharing of stories which made this a very powerful and memorable offering. The platform was easy to navigate. I also appreciated the questions and the explanations. Thank you! I look forward to more content."
            <br /><br />"Great course, very informative. Thank you to everyone involved"
            <br /><br />"Very grateful for those who shared their journeys in the videos. Thank you."
            <br /><br />"The LGBTIQ courses are fantastic. It is interactive and easy to follow."
            <br /><br />"The tone with which the speakers addressed such emotional topics was kind, vulnerable and gentle. I also appreciated glossary. For instance, even after watching the portion on intersex, I was still a bit unclear. The glossary helped me understand."
            <br /><br />"I really resonated with the part under Role Models, `If you can't see yourself in the workplace, how can you feel like belong there`. I think this is true and important for all aspects of inclusion, not only LGBTIQ+."
            <br /><br />"Very informative and respectful, honest and vulnerable stories. As an Ally it made me sit up and listen and continueensure I am respectful of individual differences and preferences."
            <br /><br />"Thank you for the education. It is great to be aware in order to effectively provide support and show empathy."
            
          </p>
        </TestimonialCard>
        <TestimonialCard author={'Kasia Miceli'} organizationDetails={'Organisational Development Consultant, Return To Work SA'} logo={{src: '/participant-logos/returntoworksa-logo-carousel.png', alt: 'Return to Work SA Logo'}}>
          <p>The SBS Inclusion Program has seamlessly integrated to our inclusion strategies, helping our employees to freely and comfortably interact with their colleagues, customers and the broader community. The program is presented in an interactive, informative and very engaging manner. The short, bite-size videos and activities help employees test their learning, and the additional materials provide teams with the opportunity to discuss and apply their understanding. Importantly, having a web-based platform ensures employees can access the training no matter where they’re working, and fit it in to their schedules.</p>
        </TestimonialCard>
        <TestimonialCard author={'Client'} organizationDetails={'The Y Australia'} logo={{src: '/participant-logos/they-logo-carousel.png',alt: 'The Y Logo'}}>
          <p>The Inclusion Program rollout within Y Australia has been incredibly positive from both an educative and wellbeing perspective. We have engaged staff and volunteers across our organisation at all levels, from service delivery to executive leadership and board directors. The modules have been very popular and well received with high levels of completion across the board. The modules are accessible, varied in their content delivery and allow participants to progress at their own pace. The Inclusion Program support staff made the roll out exceptionally easy, were always on hand to field questions and went above and beyond in their support of our learning journey. A fantastic program and well worth the investment.
          <br /><strong>The Y Australia</strong></p>
          <p>"The Inclusion Program certainly opened my eyes to my own unconscious biases and society’s more broadly. It particularly helped me understand gender inequity, the importance of non-binary language and be less afraid of ‘getting it wrong’ which many of our generation (Gen X and beyond) fear. I’ve already seen change in the way we do things at the Y coming to life as a direct result of our shared learning through our commitment to all our staff and board taking part in the program."
          <br /><strong>Executive Manager, Brand & Communications at Y Australia</strong></p>
          <p>"The Inclusion Program has really opened my eyes to a lot of societal issues that are currently being faced and has already had a bigger impact in my personal life than I’d originally thought. Personally, I found learning about Aboriginal culture and the dark history regarding the Stolen Generation as one of the key learning points and something that all Australians should know about. I would recommend this program to everyone and would like to thank the Inclusion Program staff for the support during the training."
          <br /><strong>Insurance Coordinator at Y Australia</strong>
          </p>
      
        </TestimonialCard>
        <TestimonialCard author={'Penny Chapman'} organizationDetails={"Project Manager, Jenny's Place"}>
          <p>Our entire workforce completed the Core Inclusion course. The overall feedback was so positive. 
          Everyone loved the short videos which we found were a lot more interactive than simply reading and 
          a great way to demonstrate specific examples. The course was also a great way to build on our already 
          developed foundation skills, but also help our organisation think about ways to be more inclusive of 
          diverse groups. One staff member stated the course “brought that spark back and made me want to do better.</p>
        </TestimonialCard>       
          <TestimonialCard author={"A collection of employees' feedback"} organizationDetails={"DPV Health"}>
            <p>The course is just amazing.”</p>
            <p>"I just wanted to say I completed the Aboriginal & Torres Straight Islander course and it made me cry. I’m so thankful DPV organised this because honestly it gave me a higher understanding of their culture and the importance of keeping it known."</p>
            <p>"I found the videos engaging and emotional. This was a good teaching tool."</p>
            <p>"It was most beneficial to hear lived experience - this offers the most powerful learning.  Offered a reminder to remain respectfully curious."</p>
            <p>"I loved learning about Aboriginal and Torres Strait Islander people - I learnt so much.  I also appreciated learning more about how to respect transgender people and their own personal challenges."</p>
            <p>"Listening to personal experiences of people who may have experienced discrimination was quite powerful and reinforced the importance of promoting equality"</p>
        </TestimonialCard>    
        <TestimonialCard author={'Genevieve Nolan'} organizationDetails={'Policy & Programs Manager - Consumer Services, Musculoskeletal Australia'} logo={{src: '/participant-logos/musculoskeletal-logo.png',alt: 'Musculoskeletal Australia'}}>
          <p>The online and self-paced format made it very accessible, convenient and easy to use and the content was comprehensive, informative, thought-provoking and very well presented. The support of SBS staff during our organisation’s use of the Inclusion Program was also excellent, in that they responded to any queries in a very timely and helpful manner.</p>
        </TestimonialCard>     
      </div>
      <div className='testimonial-card__column'>
        <TestimonialCard author={'Cathy Capogreco'} organizationDetails={'Rail Projects Victoria, Major Transport Infrastructure Authority'} logo={{src: '/participant-logos/railproject-logo-carousel.png', alt: 'Rails Projects Victoria Logo'}}>
          <p>The SBS modules have really assisted to support, promote and drive diversity and inclusion initiatives in our workplace. For example, the Aboriginal and Torres Strait Islander module forms part of a blended training approach and the LGBTIQ+ module is a great complimentary resource to our LGBTIQ+ Safety in Transport facilitated session</p> 
          <p>Finally, no Diversity and Inclusion awareness day is promoted at RPV without taking the opportunity to refer our team members to the relevant module in the series. Congratulations SBS on creating such an informative and engaging series of modules.
          </p>      
        </TestimonialCard>
        <TestimonialCard author={'Department of Customer Service NSW Government'} organizationDetails={'Revenue NSW'}>
          <p>My team have provided glowing feedback on both the Culture and Indigenous courses they have undertaken. </p>
          <p>
          The Culture course has helped them build broader cultural competency to get an understanding of Australia by numbers. This has then been complimented by the Indigenous course, focusing on inclusion, stereotyping, getting to know the culture and the impacts of colonisation and helping them understand why there may be a disconnect with our First Nations People and Govt services due to past history.
          </p>
        </TestimonialCard>
        <TestimonialCard author={'Client'} organizationDetails={'City of Bunbury Council WA'}>
          <p>The <strong>SBS Inclusion Program has been extremely beneficial</strong> to the entire City of Bunbury. Due to the engaging video format of the SBS Inclusion Program, staff were able to complete the training on flexible arrangements or as a group. The <strong>interactive training</strong> approach has <strong>helped our organisation embrace our diversities</strong>, while creating a safe and happy place to work.</p>      
        </TestimonialCard>
        <TestimonialCard author={'Rebecca Capper'} organizationDetails={'People and Culture Manager, Screen QLD'}>
          <p>There is no better way to <strong>build awareness and cultural competence than through storytelling</strong>. I enjoyed the opportunity to work through each topic discretely rather than a continuous program and am grateful to those individuals who shared their experiences and stories to <strong>enhance our learning journey.</strong>
          </p>
        </TestimonialCard>
        <TestimonialCard author={'Aaron Claridge'} organizationDetails={'Area Manager, Unidex Healthcare'} logo={{src: '/participant-logos/unidex-testimonial-logo.png', alt: 'Unidex Healthcare Logo'}} >
          <p>The Indigenous course allows our staff to have a platform that provides a great range of 
            information supported by personal stories that make it easy to connect with and deepen our knowledge and understanding.</p>
        </TestimonialCard>
        <TestimonialCard author={'Janet Rodger'} organizationDetails={'CEO, Yoga Australia'}>
          <p>This course is fabulous. It provides <strong>a great range of information supported by personal stories</strong> that make it easy to connect 
            with and deepen your knowledge and understanding.</p>
        </TestimonialCard>
        <TestimonialCard author={'Human Resources'} organizationDetails={'Gowrie SA'}>
          <p>The Core Inclusion course built foundation skills and knowledge and created an opportunities for reflective dialogue among our teams around inclusion and unconscious bias, specifically around some diversity dimensions such as Aboriginal and Torres Strait Islander peoples, LGBTIQ+, gender, disability, culture and age. </p>
          <p>
          The course is highly engaging, using animated, bright and clear videos for all to understand. The videos also use storytelling so participants can empathise with the storyteller, which also allowed for complex information to be understood. </p>
          <p>The SBS client portal is well set out and user friendly.</p>
        </TestimonialCard>
        <TestimonialCard author={'Garry Ellem'} organizationDetails={'Lake Macquarie City Council – Holiday Parks'}>
          <p>The SBS Inclusion Program is fantastic for delivering bite sized snippets of training. It has been really well received from our team with many of them coming up with little gold nuggets of wisdom from the training. I highly recommend any organisation to take on the SBS Inclusion Program.</p>
        </TestimonialCard>
        <TestimonialCard author={"Amy O'Brien"} organizationDetails={'Manager Education, Seymour Health'}>
          <p>We are really happy with the inclusion program. The modules are insightful and have started real conversations within our workplace.</p>
        </TestimonialCard>  
        <TestimonialCard author={"Sonia Cherico"} organizationDetails={'Human Resource Officer, Mindarie Regional Council'}>
          <p>The program assisted staff awareness of inclusion and cultural understandings, they enjoyed the storytelling and the ability to take one module at a time…the videos are engaging and emotional, listening to personal experiences of people who have experienced discrimination reinforced the importance of promoting equality.</p>
        </TestimonialCard>                 
      </div>
    </div>
  )
}

export default Testimonials