import React from "react";
import Carousel from '../shared/Carousel'
import TestimonialCard from './TestimonialCard'


const TestimonialCarousel = (props) => {
  return(
    <Carousel>
      {/* <BupaTestimonial cardClass={'testimonial-card--carousel'}/> */}
      <TestimonialCard author={'Corrie Playford-Browne'} organizationDetails={'Organisational Development Lead - Diversity & Inclusion, Bupa'} cardClass={'testimonial-card--carousel'}>
      <p>We have enjoyed the <strong>Core Inclusion</strong> and <strong>First Nations</strong> awareness courses. We have received an 
        outpouring of support from our employees who have really enjoyed the <strong>engaging, 
        high-quality production</strong> of both modules. We have seen our completion rates 
        increase to numbers we’ve never seen before. The <strong>modules have been so 
        successful</strong>…and the team have been so accommodating and easy to work with. Thank you SBS!
      </p>
      </TestimonialCard>      
      <TestimonialCard author={'Client'} organizationDetails={'Gilead Sciences'} logo={{src: '/participant-logos/gilead-logo-carousel.png', alt: 'Gilead Logo'}} cardClass={'testimonial-card--carousel'}>
      <p>A <strong>well done educational event</strong>. I am a strong ally. There is always something to learn. 
        I appreciate the <strong>sharing of stories</strong> which made this a <strong>very powerful</strong> and memorable offering. 
        The platform was <strong>easy to navigate</strong>. I also appreciated the questions and the explanations. 
        Thank you! I look forward to more content.</p>
        <p><strong>Very grateful</strong> for those who <strong>shared their journeys</strong> in the videos. Thank you.</p>
      </TestimonialCard>
      <TestimonialCard author={'Kasia Miceli'} organizationDetails={'Organisational Development Consultant, Return To Work SA'} logo={{src: '/participant-logos/returntoworksa-logo-carousel.png', alt: 'Return to Work SA Logo'}} cardClass={'testimonial-card--carousel'}>
        <p>The <strong>SBS Inclusion Program</strong> has <strong>seamlessly integrated to our inclusion strategies</strong>, helping our employees to freely and 
        comfortably interact with their colleagues, customers and the broader community. The program is presented in an <strong>interactive, 
        informative</strong> and very <strong>engaging</strong> manner. The short, <strong>bite-size videos</strong> and activities help employees test their learning, 
        and the <strong>additional materials</strong> provide teams with the <strong>opportunity to discuss and apply</strong> their understanding. Importantly, 
        having a web-based platform ensures employees can access the training no matter where they're working, and fit it in to their schedules.</p>
      </TestimonialCard>

      <TestimonialCard author={'Client'} organizationDetails={'The Y Australia'} logo={{src: '/participant-logos/they-logo-carousel.png',alt: 'The Y Logo'}} cardClass={'testimonial-card--carousel'}>
        <p>The <strong>modules have been very popular</strong> and well received with high levels of completion across the board. The <strong>modules are accessible</strong>, varied in their content delivery and <strong>allow participants to progress at their own pace</strong>. The Inclusion Program support staff made the <strong>roll out exceptionally easy</strong>, were always on hand to field questions and went above and beyond in their support of our learning journey. A <strong>fantastic program</strong> and well worth the investment.</p>
      
      </TestimonialCard>
      <TestimonialCard author={'Cathy Capogreco'} organizationDetails={'Rail Projects Victoria, Major Transport Infrastructure Authority'} logo={{src: '/participant-logos/railproject-logo-carousel.png', alt: 'Rails Projects Victoria Logo'}} cardClass={'testimonial-card--carousel'}>
        <p>The SBS modules have really <strong>assisted to support, promote and drive diversity and inclusion initiatives in our workplace</strong>. For example, the <strong>First Nations</strong> course forms part of a blended training approach and the <strong>LGBTIQ+</strong> course is a great complimentary resource…Finally, no Diversity and Inclusion awareness day is promoted at RPV without taking the opportunity to refer our team members to the relevant module in the series. <strong>Congratulations SBS on creating such an informative and engaging series of courses.</strong></p>      
      </TestimonialCard>
      <TestimonialCard author={'Department of Customer Service NSW Government'} organizationDetails={'Revenue NSW'} cardClass={'testimonial-card--carousel'}>
        <p>The <strong>Cultural Diversity</strong> course has <strong>helped our employees build broader cultural competency</strong> to get an <strong>understanding of Australia</strong>. This has then been complimented by the <strong>First Nations</strong> course, <strong>focusing on inclusion, stereotyping</strong>, getting to know the cultures and the <strong>impacts of colonisation</strong> and helping our employees understand why there may be a disconnect with our First Nations Peoples and Government services due to the past history.</p>
      </TestimonialCard>

      <TestimonialCard author={'Client'} organizationDetails={'City of Bunbury Council WA'} cardClass={'testimonial-card--carousel'}>
        <p>The <strong>SBS Inclusion Program has been extremely beneficial</strong> to the entire City of Bunbury. Due to the engaging video format of the SBS Inclusion Program, staff were able to complete the training on flexible arrangements or as a group. The <strong>interactive training</strong> approach has <strong>helped our organisation embrace our diversities</strong>, while creating a safe and happy place to work.</p>      
      </TestimonialCard>
      <TestimonialCard author={'Rebecca Capper'} organizationDetails={'People and Culture Manager, Screen QLD'} cardClass={'testimonial-card--carousel'}>
        <p>There is no better way to <strong>build awareness and cultural competence than through storytelling</strong>. I enjoyed the opportunity to work through each topic discretely rather than a continuous program and am grateful to those individuals who shared their experiences and stories to <strong>enhance our learning journey.</strong>
        </p>
      </TestimonialCard>
    </Carousel>
  )
}

export default TestimonialCarousel